// ChatInterface.js
import React, { useState, useEffect, useRef } from "react";
import { Card, Form, Button, Spinner } from "react-bootstrap";
import { ArrowRightCircle, ChevronUp, ChevronDown } from "react-bootstrap-icons";
import "./ChatInterface.css";

function ChatInterface() {
  const [inputText, setInputText] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (isChatOpen && messages.length === 0) {
      sendIntroMessage();
    }
  }, [isChatOpen]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  const sendIntroMessage = () => {
    const introMessage = {
      text: "Welcome! Would you like to sign up for our Beta program?",
      sender: "assistant",
    };
    setMessages([introMessage]);
  };

  const sendMessage = async () => {
    if (inputText.trim()) {
      const newMessage = { text: inputText, sender: "user" };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInputText("");
      setLoading(true);

      try {
        const response = await fetch(`https://dev.froh.ai/api`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            message: inputText,
            agentId: "TFB",
          }),
        });

        const data = await response.json();
        if (data.message) {
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: data.message, sender: "assistant" },
          ]);
        }
      } catch (error) {
        console.error("Error sending message:", error);
      }

      setLoading(false);
    }
  };

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  return (
    <div className={`chat-container ${isChatOpen ? "open" : ""}`}>
      <div className="chat-header bg-success text-white" onClick={toggleChat}>
        <span>Join the Beta</span>
        {isChatOpen ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
      </div>
      {isChatOpen && (
        <Card className="chat-card shadow-lg">
          <Card.Body className="chat-body" ref={chatContainerRef}>
            {messages.map((message, index) => (
              <div
                key={index}
                className={`d-flex flex-row ${
                  message.sender === "user" ? "justify-content-end" : "justify-content-start"
                } mb-3`}
              >
                <div
                  className={`chat-bubble ${
                    message.sender === "user" ? "user-bubble" : "assistant-bubble"
                  }`}
                >
                  {message.text}
                  <div className="bubble-arrow"></div>
                </div>
              </div>
            ))}
          </Card.Body>
          <Card.Footer className="chat-footer">
            <Form.Control
              type="text"
              placeholder="Type a message"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  sendMessage();
                }
              }}
              className="message-input"
            />
            <Button variant="link" className="send-button" onClick={sendMessage} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : <ArrowRightCircle size={24} />}
            </Button>
          </Card.Footer>
        </Card>
      )}
    </div>
  );
}

export default ChatInterface;